<template>
  <div class="container container-list">
    <div class="content_box">
      <div class="topTitle">
        <div>题库列表</div>
        <div>
          <el-button size="small" type="primary" @click="toBatchImport">基础性作业批量导入</el-button>
        </div>
      </div>
      <el-form inline ref="form" class="search" :model="form" size="small">
        <el-form-item label="年级">
          <el-select v-model="form.grade" placeholder="请选择年级" @change="reReloadSource">
            <el-option :label="item.value" :value="item.id" v-for="item in grades" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科目">
          <el-select v-model="form.subject" placeholder="请选择科目" @change="reReloadSource">
            <el-option :label="item.value" :value="item.id" v-for="item in subjects" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教材">
          <el-select v-model="teachingMaterialId" placeholder="请选择教材" @change="materialChange">
            <el-option :label="item.name + '-' + item.printingTimes"
                       :value="item.id"
                       v-for="item in sources"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页码">
          <el-input v-model="form.pageNumber" placeholder="请输入页码" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>

        <!-- <el-form-item label="题干">
          <el-input v-model="form.stem" placeholder="请输入题干"></el-input>
        </el-form-item> -->
        <el-form-item label="录题员">
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>

        <el-form-item class="search-footer">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="default" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 审核状态 -->
      <div class="state_box display_start">
        <div class="state"
             :class="{on: form.status == null}"
             @click="check('ALL')">全部
        </div>
        <div class="state"
             :class="{on: form.status === 'PENDING_REVIEW'}"
             @click="check('PENDING_REVIEW')">待审核
        </div>
        <div class="state"
             :class="{on: form.status === 'REVIEW_PASSED'}"
             @click="check('REVIEW_PASSED')">审核通过
        </div>
        <div class="state2"
             :class="{on: form.status === 'REVIEW_FAILURE'}"
             @click="check('REVIEW_FAILURE')">审核失败
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :header-cell-style="{background:'#CCF4E0',color:'#000000'}"
        :data="pagination.list"
        stripe
        style="width: 100%">
        <el-table-column
          width="80"
          prop="id"
          label="ID"
          :formatter="formatter"
        >
        </el-table-column>
        <el-table-column
          prop="stem"
          label="题干"
          width="320">
          <template slot-scope="scope" >
<!--            <div v-html="scope.row.stem"></div>-->
            <ui-mathpad :value="scope.row.stem" />
          </template>
        </el-table-column>
        <el-table-column
          prop="titleNumber"
          label="题号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="questionTypeName"
          label="题型"
          width="100">
        </el-table-column>
        <el-table-column
          prop="degreeOfDifficultyName"
          label="难易程度"
          width="100">
        </el-table-column>
        <el-table-column
          prop="pageNumber"
          label="页码"
          width="100">
        </el-table-column>
        <el-table-column
          prop="gradeName"
          label="年级"
          width="130">
        </el-table-column>
        <el-table-column
          prop="questionStatusName"
          label="状态"
          width="130">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="200">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="240">
          <template slot-scope="scope">
            <el-button @click="watchInfo(scope.row.id)" type="text">查看</el-button>
            <el-button type="text" @click="examine(scope.row.id)" v-if="scope.row.status ==='PENDING_REVIEW'">审核</el-button>
            <el-button type="text" @click="openSet(scope.row.id)" v-if="scope.row.status !=='REVIEW_PASSED'">编辑</el-button>
             <el-button type="text" @click="errorCorrect(scope.row.id)" v-if="scope.row.status ==='REVIEW_PASSED'">纠错</el-button>
            <el-button type="text" @click="deleteQuestionBank(scope.row.id)" v-if="scope.row.status !=='REVIEW_PASSED'" v-loading.fullscreen.lock="fullscreenLoading">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <div style="width:100%;height:70px;text-align:right;">
        <el-pagination
          style="margin-top:20px"
          @size-change="sizeChange"
          @current-change="currentChange"
          :page-size="pagination.size"
          :page-count="pagination.totalPage"
          :current-page.sync="pagination.currentPage"
          :background="true"
          :hide-on-single-page="false"
          layout="prev, pager, jumper,next,total"
          ref="pagination"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="审核"
      :visible.sync="dialogTableVisible"
      width="30%">
      <el-radio-group v-model="questionExamineStatus">
        <el-radio label="REVIEW_PASSED">审核通过</el-radio>
        <el-radio label="REVIEW_FAILURE">审核失败</el-radio>
        <el-input
          style="margin-top: 10px;"
          v-if="questionExamineStatus==='REVIEW_FAILURE'"
          type="textarea"
          :rows="4"
          placeholder="请输入失败原因"
          :maxlength="255"
          show-word-limit
          v-model="questionExamineFailureMsg">
        </el-input>
      </el-radio-group>
      <span slot="footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="examineEvent">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getDegree, getGradeName, getQuestionStatusName, grade, subject } from '@/utils/enums'
import { getMaterialList } from '@/api/material'
import { SETTING } from '@/config/setting'
import { deleteQuestionBank, getQuestionBankList, questionBankAudit } from '@/api/question-bank'

// function htmlTrim (str) {
//   return str.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
// }

// 搜索表单默认值
function searhDefault (query) {
  const m = {
    pageNumber: null,
    grade: null,
    subject: null,
    phone: null,
    status: null
  }
  const formDefault = {
    grade: 'FIFTH_GRADE',
    subject: 'MATHEMATICS'
  }
  return query && Object.keys(query).length > 0
    ? Object.assign(m, query)
    : Object.assign(m, formDefault)
}

// 搜索参数格式化
function searchQS (form, data = {}) {
  return Object.keys(form).reduce((data, key) => {
    if (form[key]) {
      data[key] = form[key]
    }
    return data
  }, data)
}

export default {
  created () {
    const { params, query } = this.$route
    this.form = searhDefault(query)
    this.loadMaterialList()
    this.pagination.currentPage = params.page ? Number(params.page) : 1
    this.loadData()
  },
  data () {
    return {
      deleteQuestionBankId: null,
      record: null,
      questionExamineStatus: 'REVIEW_PASSED',
      questionExamineId: null,
      questionExamineFailureMsg: null,
      pagination: {
        currentPage: 1,
        size: 20,
        total: 0,
        totalPage: 0,
        list: []
      },
      grades: grade,
      subjects: subject,
      sources: [],
      dialogTableVisible: false,
      radio: '1',
      checkNum: 1,
      form: {
        pageNumber: null,
        grade: null,
        subject: null,
        phone: null,
        status: null
      },
      fullscreenLoading: false,
      teachingMaterialId: null
    }
  },
  methods: {
    formatter (row, column, cellValue, index) {
      const currentPage = this.pagination.currentPage - 1
      const size = this.pagination.size
      return currentPage * size + index + 1
    },

    toBatchImport () {
      this.$router.push('/batchImport')
    },

    // 分页跳转
    currentChange (num) {
      // this.pagination.currentPage = num
      this.start()
    },
    sizeChange (size) {
      this.pagination.currentPage = 1
      this.pagination.size = size
      this.start()
    },

    // 选择教材查询数据
    materialChange (e) {
      this.teachingMaterialId = e
      this.loadData()
    },

    // 设置路由&加载数据
    start () {
      this.$router.replace({
        name: 'List',
        params: { page: this.pagination.currentPage },
        query: searchQS(this.form)
      })
        .then(() => { this.loadData() })
        .catch(e => { console.log(e) })
    },
    loadData () {
      const parameters = searchQS(this.form, {
        teachingMaterialId: this.teachingMaterialId,
        size: this.pagination.size,
        currentPage: this.pagination.currentPage
      })

      getQuestionBankList(parameters).then(result => {
        if (result.code === SETTING.NUMBER.ZERO) {
          const list = []
          let index = 0
          result.data.list.forEach(item => {
            item.index = ++index + (this.pagination.currentPage - 1) * this.pagination.size
            // item.stem = htmlTrim(item.stem).substring(0, 26)
            // item.questionTypeName = getQuestionType(item.questionType)
            item.gradeName = getGradeName(item.grade)
            item.questionStatusName = getQuestionStatusName(item.status)
            item.degreeOfDifficultyName = getDegree(item.degreeOfDifficulty)
            list.push(item)
          })
          result.data.list = list
          this.pagination = result.data
        }
      })
    },

    // 搜索表单查询
    search () {
      this.pagination.currentPage = 1
      this.start()
    },
    // 重置表单
    reset () {
      this.form = searhDefault()
      this.teachingMaterialId = null
      this.loadMaterialList()
      this.loadData()
      this.start()
    },
    // 搜索条件：年级、科目变更
    reReloadSource () {
      this.teachingMaterialId = null
      this.loadMaterialList()
      this.loadData()
    },
    // 加载教材列表
    loadMaterialList () {
      this.sources = []
      if (this.form.grade != null && this.form.subject != null) {
        getMaterialList(this.form.grade, this.form.subject).then(response => {
          if (response.code === SETTING.NUMBER.ZERO) {
            this.sources = response.data
          }
        })
      }
    },

    deleteQuestionBank (id) {
      this.deleteQuestionBankId = id
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        deleteQuestionBank({ id: this.deleteQuestionBankId }).then(response => {
          if (response.code === SETTING.NUMBER.ZERO) {
            this.$message({ type: 'success', message: '删除成功' })
            this.loadData()
          }
          this.fullscreenLoading = false
        }).catch(() => {
          this.loadData()
          this.fullscreenLoading = false
        })
      }).catch(() => {
        this.fullscreenLoading = false
      })
    },
    openSet (id) {
      this.$router.push('/Edit/' + id)
    },
    // 纠错
    errorCorrect (id) {
      this.$router.push('/errorCorrect/' + id)
    },
    // 切换审核状态
    check (status) {
      if (status !== 'ALL') {
        this.form.status = status
      } else {
        this.form.status = null
      }
      this.start()
    },
    // 审核
    examine (id) {
      this.questionExamineStatus = 'REVIEW_PASSED'
      this.questionExamineId = id
      this.questionExamineFailureMsg = null
      this.dialogTableVisible = true
    },
    // 确定审核
    examineEvent () {
      if (this.questionExamineStatus === 'REVIEW_FAILURE' && !this.questionExamineFailureMsg) {
        return this.$message({ type: 'error', message: '请输入审核失败原因' })
      }
      questionBankAudit({
        questionBankId: this.questionExamineId,
        status: this.questionExamineStatus,
        failReason: this.questionExamineFailureMsg
      }).then(response => {
        if (response.code === SETTING.NUMBER.ZERO) {
          this.loadData(this.pagination.currentPage)
          this.$message({ type: 'success', message: '审核完成' })
        } else {
          this.$message({ type: 'error', message: response.msg })
        }
      }).catch(err => {
        console.log(err)
        this.$message({ type: 'error', message: '审核失败' })
      }).finally(() => {
        this.dialogTableVisible = false
      })
    },
    watchInfo (id) {
      this.$router.push('/topic/' + id)
    }
  }
}
</script>

<style lang="scss">
.container-list {
  overflow-y: scroll;
}

.topTitle {
  margin: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-button--primary {
    background: #1EB180;
    border-color: #1EB180;
  }
}

.content_box {
  width: 80%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 26px;
  font-weight: normal;
  color: #333333;

  .state_box {
    margin: 0 0 15px 0;

    .state {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      border-left: 1px solid #999999;
      border-top: 1px solid #999999;
      border-bottom: 1px solid #999999;
      cursor: pointer;
    }

    .state2 {
      border: 1px solid #999999;
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }

    .on {
      border-bottom-color: #1EB180;
      color: #1EB180;
    }
  }
}

// 搜索表单
.search {
  &.el-form--inline {
    display: flex;
    flex-wrap: wrap;
  }

  .el-form-item {
    display: flex;
    width: 240px !important;
    margin-right: 30px;

    &__label {
      word-break: keep-all;
      color: #000;
    }

    &__content {
      flex-grow: 1;
    }
  }

  &-footer {
    flex-grow: 1;
    text-align: center;

    .el-button {
      min-width: 80px;
    }

    .el-button--primary {
      background: #1EB180;
      border-color: #1EB180;
    }
  }
}

.el-table th > .cell {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.el-table td > .cell {
  .question_image {
    max-width: 310px !important;
  }
}
.cell div img{
  max-height: 200px;
}
</style>
